import React, { ReactNode } from 'react';

interface ComponentProps {
  className?: string;
  end?: boolean;
  start?: boolean;
  between?: boolean;
  around?: boolean;
  center?: boolean;
  column?: boolean;
  children: ReactNode;
}

const Holder: React.FC<ComponentProps> = ({
  className = '',
  end = false,
  start,
  between,
  around,
  center,
  column,
  children
}: ComponentProps): JSX.Element => {
  const classNames = `holder ${className || ''}${end ? ' end' : ''}${start ? ' start' : ''}${between ? ' between' : ''}${around ? ' around' : ''}${center ? ' center' : ''}${column ? ' FLEX-COL' : ''}`;

	return (
  <div className={classNames}>
    {children}
  </div>
  )
}

export default Holder;